import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import CloseIcon from '../../icons/Close';
import { toggleBodyScroll } from '../../../utils/toggleBodyScroll';
import { createPortal } from 'react-dom';

type Props = {
    children: React.ReactNode;
    open: boolean;
    title?: string;
    onClose: () => void;
};

const Curtain: FC<Props> = ({ open, title, onClose, children }) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);

    useEffect(() => {
        toggleBodyScroll(open);
        setTimeout(() => {
            setShowOptions(open);
        }, 30);
        return () => {
            toggleBodyScroll(false);
        };
    }, [open]);

    return createPortal(
        <div className={`${styles.curtain} ${open ? styles.open : undefined}`}>
            <div className={`${styles.content} ${showOptions ? styles.open : undefined}`}>
                <button className={styles.close} onClick={onClose}>
                    <CloseIcon size={20} />
                </button>
                <div className={styles.inner}>
                    {title && <h5 className={styles.title}>{title}</h5>}
                    {children}
                </div>
            </div>
        </div>,
        document.body,
    );
};

export default Curtain;
