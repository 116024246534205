import {
    doc,
    getDoc,
    addDoc,
    updateDoc,
    collection,
    arrayUnion,
    UpdateData,
    DocumentData,
    DocumentReference,
} from 'firebase/firestore/lite';
import { db } from '../firebase';
import { IBook } from "types/IBook";
import { Lang } from 'config/lang';
import { IRecord } from '../types/IRecord';
import { ICustomMap } from '../types/ICustomMap';
import { ISmartObject } from '../types/ISmartObject';
import { IUser } from '../types/IUser';
import { isProd } from './isProd';

export enum FirebaseCollection {
    USER = '_user',
    SMART_OBJECT = '_smart_object',
    SMART_BOOK = '_bundles',
    RECORD = '_recordings',
    AUTHOR = '_author',
    REVIEWS = '_reviews',
    WORKS = '_works',
    PRODUCTS = '_products',
}

export const hydrateSmartObject = (ref: DocumentReference<DocumentData>, data: DocumentData): ISmartObject => {
    const initData: ISmartObject = {
        ownerAvatar: {
            src: '',
        },
        recordingsList: [],
        smartObjectBook: '',
        type: 'DIGITAL BOOK',
        id: '',
    };

    return {
        ...initData,
        ...data,
        id: ref.id,
    };
};

export const getSmartObject = async (id: string): Promise<ISmartObject | null> => {
    const docRef = doc(db, FirebaseCollection.SMART_OBJECT, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (!data) {
        return null;
    }
    return hydrateSmartObject(docRef, data);
};

// export const getAuthor = async (id: string): Promise<DocumentData | null> => {
//     const docRef = doc(db, FirebaseCollection.AUTHOR, id);
//     const docSnap = await getDoc(docRef);
//     const data = docSnap.data();
//     if (!data) {
//         return null;
//     }
//     return data;
// };

export const createSmartObject = async (userId: string, smartObjectBookId: string): Promise<string> => {
    const data = {
        owner: userId,
        recordingsList: [],
        smartObjectBook: smartObjectBookId,
        type: 'DIGITAL BOOK',
        isTest: !isProd,
    };
    const docRef = await addDoc(collection(db, FirebaseCollection.SMART_OBJECT), data);
    await updateSmartObject(docRef.id, {id: docRef.id});
    return docRef.id;
};

export const convertSmartObject = async (userId: string, smartObjectid: string): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.SMART_OBJECT, smartObjectid);
    await updateDoc(recordRef, {
        owner: userId,
        type: 'CONVERTED BOOK',
    });
};

export type IUpdateSmartObject = Partial<Pick<ISmartObject, 'ownerAvatar' | 'recordingsList' | 'id'>>;

export const updateSmartObject = async (id: string, data: IUpdateSmartObject): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.SMART_OBJECT, id);
    await updateDoc(recordRef, data);
};

export const hydrateSmartBook = (ref: DocumentReference<DocumentData>, data: DocumentData): IBook => {
    const initData: IBook = {
        id: '',
        author: '',
        coverImg: {
            src: '',
        },
        lang: [],
        inLanguage: [],
        name: '',
        recordingsList: [],
        recPages: [],
        recPageImages: [],
        recPageLanguages: [],
        coverImgSquare: {
            src: '',
        },
        additionalFormats: [],
        description: '',
        ageMax: 0,
        ageMin: 0,
        shippingCountry: [],
    };

    return {
        ...initData,
        ...data,
        id: ref.id,
    };
};

export const getSmartBook = async (id: string): Promise<IBook | null> => {
    try {
        const docRef = doc(db, FirebaseCollection.SMART_BOOK, id);

        const docSnap = await getDoc(docRef);

        const data = docSnap.data();
        if (!data) {
            return null;
        }
        const book = hydrateSmartBook(docRef, data);
        let recPages;
        if (book.lang) {
            book.lang = book.lang.map(val => val.toLowerCase() as Lang);
        }
        if (book.recPageImages && book.recPageLanguages) {
            const pageLangs = book.recPageLanguages.reduce((acc: ICustomMap, { lang, pageNumber, text }) => {
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    [lang]: text,
                };
                return acc;
            }, {});
            const imgLangs = book.recPageImages.reduce((acc: ICustomMap, { lang, pageNumber, img }) => {
                const langImg = lang ? { [lang]: img.src } : {};
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    ...langImg,
                    default: img,
                };
                return acc;
            }, {});
            const cardLangs = book.recPageLanguages.reduce((acc: ICustomMap, { lang, pageNumber, cards }) => {
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    [lang]: cards || [],
                };
                return acc;
            }, {});
            recPages = Object.keys(pageLangs).map((key) => {
                // debugger
                return {
                    img: imgLangs?.[key]?.default || book.recPageImages?.[0]?.img,
                    pageNum: key.toString(),
                    text: pageLangs[key] || {},
                    imgs: imgLangs?.[key] || { default: book.recPageImages?.[0]?.img },
                    cards: cardLangs[key] || {},
                };
            });
        }
        // @ts-ignore
        return { ...book, recPages };
    } catch (error) {
        console.error(error);
    }
    return null;
};

export const hydrateRecord = (ref: DocumentReference<DocumentData>, data: DocumentData): IRecord => {
    const initData: IRecord = {
        id: '',
        createdAt: '',
        createdBy: '',
        lang: Lang.en,
        narratorAvatar: {
            src: '',
        },
        narratorVideoIntro: {
            src: '',
        },
        pages: [],
        smartObjectBook: '',
        smartObject: '',
        narratorUserId: null,
        full_transition_audio_intro_offset: 0,
        sampleAudio: {
            src: ''
        },
        sampleDurationMs: 0,
        narratorName: '',
        processing_status: '',
        recordingType: 'local',
    };

    const mapDocumentData = ({ lang, ...data }: DocumentData) => {
        return {
            ...data,
            lang: lang?.toLowerCase(),
        };
    };

    return {
        ...initData,
        ...mapDocumentData(data),
        id: ref.id,
    };
};

const upsertRecordSmartObject = async (ref: DocumentReference<DocumentData>, data: DocumentData): Promise<IRecord> => {
    const record = hydrateRecord(ref, data);

    // if (record.isOriginalNarration) {
    //     return record;
    // }

    if (record.smartObject) {
        return record;
    }

    const smartObject = await getSmartObject(record.smartObjectBook);

    if (!smartObject) {
        return record;
    }

    await updateRecord(ref.id, {
        smartObject: record.smartObjectBook,
        smartObjectBook: smartObject.smartObjectBook,
    });

    const updated = await getRecord(ref.id);

    if (!updated) {
        return record;
    }

    return updated;
};

export const getRecord = async (id: string): Promise<IRecord | null> => {
    const ref = doc(db, FirebaseCollection.RECORD, id);
    const snapshot = await getDoc(ref);
    const data = snapshot.data();
    if (!data) {
        return null;
    }
    return upsertRecordSmartObject(ref, data);
};

// export const getRecordList = async (ids: string[]): Promise<IRecord[]> => {
//     if (ids.length < 1) {
//         return [];
//     }
//     return await Promise.all(ids.map(getRecord)).then(list =>
//         list.reduce((list: IRecord[], record) => (record ? [...list, record] : list), []),
//     );
// };

// export const createEmptyRecordDoc = async () => {
//     const docRef = await addDoc(collection(db, '_recordings'), {
//         createdAt: new Date().toISOString(),
//         narratorUserId: '',
//         narratorVideoIntro: { src: null },
//         lang: '',
//         pages: [],
//         smartObjectBook: '',
//     });
//     return docRef.id;
// };

export type IUpdateRecord = Partial<
    Pick<
        IRecord,
        | 'lang'
        | 'narratorAvatar'
        | 'narratorVideoIntro'
        | 'pages'
        | 'smartObjectBook'
        | 'smartObject'
        | 'narratorUserId'
        | 'full_audio'
        | 'full_transition_audio'
        | 'narratorName'
        | 'processing_status'
        | 'recordingType'
    >
>;

export const updateRecord = async (recordId: string, data: IUpdateRecord): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.RECORD, recordId);
    await updateDoc(recordRef, data as ICustomMap);
};

export type IUpdateUser = Partial<Omit<IUser, 'id' | 'createdAt' | 'updatedAt'>>;

export const updateUser = async (id: string, updateUser: IUpdateUser): Promise<void> => {
    const data: UpdateData<IUpdateUser> = {
        ...updateUser,
    };

    if (updateUser.library) {
        data.library = arrayUnion(...updateUser.library);
    }

    await updateDoc(doc(db, FirebaseCollection.USER, id), data);
};
