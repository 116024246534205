export const handleCopy = (value: string, onDone?: () => void): void => {
    if (navigator?.clipboard && value) {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                if (onDone) onDone();
            })
            .catch(console.error);
        // @ts-ignore
        window?.DD_RUM && window.DD_RUM?.addAction('Copied recording link', { link: value });
    } else {
        // @ts-ignore
        window?.DD_RUM && window.DD_RUM?.addAction('Copy is not supported', { link: value });
        window?.console.error('Copy is not supported');
    }
};

export const handleShareLink = (value: string, onDone?: () => void): void => {
    if (navigator.share) {
        // @ts-ignore
        window.DD_RUM && window.DD_RUM?.addAction('Shared recording link', { link: value });
        navigator
            .share({
                title: 'IKs book',
                url: value,
            })
            .catch(err => {
                console.error(err);
                handleCopy(value, onDone);
            });
    } else {
        handleCopy(value, onDone);
    }
};
