const routes = {
    home: '/',
    account: {
        root: '/account',
        settings: 'settings',
        manageRecords: 'manage-records',
    },
    accountSignUp: '/sign-up',
    library: '/library',
    libraryBooks: '/library/books',
    book: '/book/:id',
    bookScan: '/book/scan',

    notFound: '/404',
    record: {
        root: '/b/:bookId/record',
        narrator: 'narrator',
        recording: 'recording',
        review: 'review',
        feedback: 'feedback',
    },
    remoteRecord: {
        root: '/remote-record',
        intro: 'intro',
        complete: 'complete',
    },
    contributor: '/contributor/:id',
};

export default routes;

export const routeID = ':id';
export const routeBookID = ':bookId';
export const routeRecordID = ':recordId';
