import { AuthorType, IBook } from '../types/IBook';
import { IRecord } from '../types/IRecord';
import { useContext, useMemo } from 'react';
import { LangContext } from '../context/lang';
import { AuthContext } from '../context/auth';
import { useQuery } from 'react-query';
import { getBookQueryKey, requestBook } from '../services/book';
import { getRecordsQueryKey, requestRecords } from '../services/record';
import { getSmartObjectRecordsQueryKey, requestSmartObjectRecords } from '../services/smatrObject';
import { getAuthorsQueryKey, requestAuthors } from '../services/author';
import { getAuthorsNames } from '../utils/langUtils';
import { Lang } from '../config/lang';

export type Settings = {
    fetchBookRecords?: boolean;
    fetchUserRecords?: boolean;
    fetchSampleRecords?: boolean;
}

type Res = {
    isIdle: boolean;
    isLoading: boolean;
    isBookNarratorsLoading: boolean;
    book: IBook | null | undefined;
    bookAuthorsName: string;
    bookNarrators: IRecord[] | undefined;
    userRecords: IRecord[] | undefined;
    bookSampleRecords: IRecord[] | undefined;
};

const useBook = (bookId: string, settings?: Settings): Res => {
    const { fetchBookRecords = true, fetchUserRecords = true, fetchSampleRecords = true } = settings || {};

    const { lang } = useContext(LangContext);
    const { user, isAuthenticated } = useContext(AuthContext);

    const { isLoading, isIdle, data: book } = useQuery({
        queryKey: [getBookQueryKey, bookId],
        queryFn: () => requestBook(bookId || undefined),
        enabled: !!bookId,
    });

    const { data: bookNarrators, isLoading: isBookNarratorsLoading } = useQuery({
        queryKey: [getRecordsQueryKey, book?.recordingsList],
        queryFn: () => requestRecords(book?.recordingsList || []),
        enabled: !!book && !!book?.recordingsList?.length && fetchBookRecords,
    });

    const { data: bookSampleRecords } = useQuery({
        queryKey: ['sampleRecords', book?.sampleRecordingList],
        queryFn: () => requestRecords(book?.sampleRecordingList || []),
        enabled: !!book && !isAuthenticated && !!book?.sampleRecordingList?.length && fetchSampleRecords,
    });

    const smartObjectId = useMemo(() => user?.library.find(userBook => userBook.smartBookId === bookId)?.smartObjectId, [user, bookId]);

    const { data: userRecords } = useQuery({
        queryKey: [getSmartObjectRecordsQueryKey, smartObjectId],
        queryFn: () => requestSmartObjectRecords(smartObjectId),
        enabled: !!user && !!smartObjectId && fetchUserRecords,
    });

    const authorIds = useMemo(() => {
        if (!book) return [];
        let ids = book?.contributors?.filter(({ type }) => type?.includes(AuthorType.author))?.map(({ id }) => id);
        if (ids?.length === 0 && book?.author) {
            ids = [book.author];
        }
        return ids || [];
    }, [book]);

    const { data: authors } = useQuery({
        queryKey: [getAuthorsQueryKey, authorIds],
        queryFn: () => requestAuthors(authorIds),
        enabled: !!book && !!authorIds.length,
    });

    const bookAuthors = useMemo(() => authors?.filter(({ types }) => types.includes(AuthorType.author)) || [], [authors]);

    const authorNames = useMemo(
        () =>
            getAuthorsNames({
                authors: bookAuthors,
                appLang: lang,
                fallbackLang: book?.inLanguage[0] || Lang.en,
            }),
        [bookAuthors, lang, book],
    );

    return {
        isIdle,
        isLoading,
        isBookNarratorsLoading,
        book,
        bookAuthorsName: authorNames.join(', '),
        bookNarrators,
        userRecords,
        bookSampleRecords,
    };
};

export default useBook;
