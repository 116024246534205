import { Lang } from 'config/lang';

export interface IBookImage {
    img: {
        src: string;
    };
    lang: Lang;
    pageNumber: number;
}

export enum CardType {
    active = 'active card',
    passive = 'passive card',
}

export type BookPageCard = {
    audio?: {
        src: string;
        duration: number;
    };
    avatar?: {
        src?: string;
        name?: string;
        id?: string | number;
    };
    cardType: CardType;
    description: string;
    id: string | number;
    name: string;
    soundEffect: string;
    text: string;
};

export type BookLangText = {
    lang: Lang;
    pageNumber: number;
    text: string;
    cards?: BookPageCard[];
};

export type BookRecPage = {
    img: {
        src: string;
    };
    pageNum: number | string;
    // lang?: Lang;
    text: {
        [key: string]: string;
    };
    imgs: {
        [key: string]: string;
    };
    cards: {
        [key: string]: BookPageCard[];
    };
};

export enum BookFormat {
    audio = 'AUDIO',
    recording = 'RECORDING',
    ebook = 'EBOOK',
}

export enum AuthorType {
    author = 'author',
    illustrator = 'illustrator',
    translator = 'translator',
    narrator = 'narrator',
    editor = 'editor',
}

export type BookImage = {
    img: {
        src: string;
    };
    lang: Lang;
};

export type BookDiscoveryMarketItem = {
    shopLink: string;
};

export type BookDescription = {
    lang: Lang;
    text: string;
};

export type LangName = {
    lang: Lang;
    name: string;
};

export type Chapter = {
    startPageNumber: number;
    chapterId: number;
    names: LangName[];
};

export type Contributor = {
    id: string;
    type: AuthorType[];
};

export interface IBook {
    id: string;
    author: string;
    coverImg: {
        src: string;
    };
    coverImgSquare: {
        src: string;
    };
    coverImgAudiobook?: BookImage[];
    coverImgEbook?: BookImage[];
    coverImg3d?: BookImage[];
    lang: Lang[];
    inLanguage: Lang[];
    name: string;
    recordingsList: string[]; // IDs to recordings
    sampleRecordingList?: string[]; // IDs to sample recordings
    recPages: BookRecPage[];
    recPageImages: IBookImage[];
    recPageLanguages: BookLangText[];
    additionalFormats: BookFormat[];
    description: string;
    descriptions?: BookDescription[];
    age?: string;
    ageMax: number;
    ageMin: number;
    paperbookPages?: number;
    audiobookDurationMin?: number;
    authorTips?: BookDescription[];
    discoveryMarket?: BookDiscoveryMarketItem[];
    chapters?: Chapter[];
    shippingCountry: string[];
    contributors?: Contributor[];
}

export type Author = {
    id: string;
    // name: string;
    firstName: string;
    lastName: string;
    names: LangName[];
    avatar?: {
        src: string;
    };
    description?: string;
    contributed: {
        bookIDs?: string[];
    };
    types: AuthorType[];
    followers: {
        count?: number;
        userIDs?: string[];
    }
};
