import { BookRecPage, IBook } from '../types/IBook';
import { collection, doc, DocumentData, getDoc, getDocs, query, where } from "firebase/firestore/lite";
import { db } from '../firebase';
import { Lang } from '../config/lang';
import { ICustomMap } from '../types/ICustomMap';
import { FirebaseCollection } from "../utils/firebase";

export const getBookQueryKey = 'smartBook';
export const getBooksQueryKey = 'smartBooks';

export const hydrateBook = (bookId: string, data: DocumentData): IBook => {
    const initData: IBook = {
        id: '',
        author: '',
        coverImg: {
            src: '',
        },
        lang: [],
        inLanguage: [],
        name: '',
        recordingsList: [],
        recPages: [],
        recPageImages: [],
        recPageLanguages: [],
        coverImgSquare: {
            src: '',
        },
        additionalFormats: [],
        description: '',
        ageMax: 0,
        ageMin: 0,
        shippingCountry: [],
    };

    return {
        ...initData,
        ...data,
        id: bookId,
    };
};


export const requestBook = async (id?: string): Promise<IBook | null> => {
    if (!id) {
        return null;
    }
    try {
        const docRef = doc(db, FirebaseCollection.SMART_BOOK, id);

        const docSnap = await getDoc(docRef);

        const data = docSnap.data();

        if (!data) {
            return null;
        }
        const book = hydrateBook(docRef.id, data);

        let recPages: BookRecPage[] = [];

        if (book.lang) {
            book.lang = book.lang.map(val => val.toLowerCase() as Lang);
        }
        if (book.recPageImages && book.recPageLanguages) {
            const pageLangs = book.recPageLanguages.reduce((acc: ICustomMap, { lang, pageNumber, text }) => {
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    [lang]: text,
                };
                return acc;
            }, {});
            const imgLangs = book.recPageImages.reduce((acc: ICustomMap, { lang, pageNumber, img }) => {
                const langImg = lang ? { [lang]: img.src } : {};
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    ...langImg,
                    default: img,
                };
                return acc;
            }, {});
            const cardLangs = book.recPageLanguages.reduce((acc: ICustomMap, { lang, pageNumber, cards }) => {
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    [lang]: cards || [],
                };
                return acc;
            }, {});

            recPages = Object.keys(pageLangs).map(key => {
                return {
                    img: imgLangs?.[key]?.default || book.recPageImages?.[0]?.img,
                    pageNum: key.toString(),
                    text: pageLangs[key] || {},
                    imgs: imgLangs?.[key] || { default: book.recPageImages?.[0]?.img },
                    cards: cardLangs[key] || {},
                } as BookRecPage;
            });
        }
        return { ...book, recPages };
    } catch (error) {
        console.error(error);
    }
    return null;
};

export const requestBooks = async (ids: string[]): Promise<IBook[]> => {
    if (ids.length < 1) {
        return [];
    }
    return await Promise.all(ids.map(requestBook)).then(list =>
        list.reduce((list: IBook[], resp) => (resp ? [...list, resp] : list), []),
    );
};

export const requestRelatedBooks = async (markets: string[]): Promise<IBook[]> => {
    if (!markets) {
        return [];
    }
    const q = query(collection(db, FirebaseCollection.SMART_BOOK), where('shippingCountry', 'array-contains-any', markets), where('isDeleted', '!=', true));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
        return [];
    }
    return snapshot.docs.reduce((list: IBook[], doc) => {
        return [...list, hydrateBook(doc.ref.id, doc.data())];
    }, []);
};

